<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listCouponDetailAPI,
  listCouponDetailSummaryAPI,
} from "@/api/vip/report/couponQuery"; //会员优惠券接口
let couponStatusList = [
  { label: "在库", value: "0" },
  { label: "已领出", value: "1" },
  { label: "已售出", value: "2" },
  { label: "售出退出库", value: "3" },
  { label: "已使用", value: "4" },
  { label: "代理商领取", value: "5" },
  { label: "已过期", value: "6" },
  { label: "已作废", value: "7" },
];
export default {
  name: "couponQuery",
  dicts: [
    "coupon_mode", //优惠券形式
    "coupon_case_type", //优惠券类型
  ],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        uuid: "87ad7290-03dc-52ca-056b-047587c78a49",
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listCouponDetailAPI,
        getSummaryApi: listCouponDetailSummaryAPI,
        title: "会员优惠券查询",
        rowKey: "couponCaseId",
        exportOption: {
          exportApi: "vipCouponQuery",
          exportName: "会员优惠券",
        },
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "coupon_mode",
            option: {
              filter: "couponModes",
              labelWidth: 90,
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "coupon_case_type",
            option: {
              filter: "couponCaseTypes",
              labelWidth: 90,
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            type: "input",
            label: "优惠券方案名称",
            model: "",
            filter: "couponCaseName",
            seniorSearch: true,
            labelWidth: 120,
          },
          {
            type: "input",
            label: "优惠券号",
            model: "",
            filter: "couponNo",
            seniorSearch: true,
          },
          {
            type: "input",
            label: "面值",
            model: "",
            filter: "parValue",
            seniorSearch: true,
          },
          {
            label: "优惠券状态",
            labelWidth: 90,
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "couponStatuss",
            option: {
              data: couponStatusList,
              label: "label",
              value: "value",
              filterable: true,
              multiple: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "billDate",
            label: "领用时间",
            minWidth: 150,
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 150,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 150,
          },
          {
            prop: "couponModeName",
            label: "优惠券形式",
            minWidth: 150,
          },
          {
            prop: "couponCaseTypeName",
            label: "优惠券类型",
            minWidth: 150,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 150,
          },
          {
            prop: "couponNo",
            label: "优惠券号",
            minWidth: 150,
          },
          {
            prop: "parValue",
            label: "面值",
            minWidth: 150,
          },
          {
            prop: "couponQty",
            label: "张数",
            minWidth: 150,
          },
          {
            prop: "couponStatusName",
            label: "优惠券状态",
            minWidth: 150,
          },
        ],
        summary: ["parValue", "couponQty"],
      },
    };
  },
};
</script>
