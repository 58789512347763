<!-- 分销员会员子会员查询 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { distributionSonVipSummary, distributionSonVipSummarySummary } from "@/api/vip/distribution/subVipCardQuery"; //分销员子会员查询

export default {
  name: "room",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "20634d06-1ca0-f43c-ccdd-ff388543cff3",
        summary: ["unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: distributionSonVipSummary,
        getSummaryApi: distributionSonVipSummarySummary,
        exportOption: {
          fastExportUrl: "/api/system/vip/distribution/reportForms/distributionSonVipSummaryExport",
          exportName: "分销员子会员查询",
        },
        body: {},
        listNo: true, // 序号
        title: "分销员子会员查询",
        check: [], // 选中数据
        rowKey: "1703967014381789184",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "分销员编号/分销员名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "distributionManVipNos", label: "分销员编号" },
              { filter: "distributionManVipNames", label: "分销员名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          // {
          //   type: "button",
          //   tip: "高级查询",
          //   btnType: "primary",
          //   click: "seniorSearch",
          // },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          // {
          //   prop: "billNo",
          //   label: "单据编号",
          //   type: "link",
          //   click: "routerLink",
          //   minWidth: 170,
          // },
          // {
          //   prop: "billDate",
          //   label: "单据日期",
          //   minWidth: 100,
          // },
          // {
          //   prop: "billTypeName",
          //   label: "单据类型",
          //   minWidth: 110,
          // },
          {
            prop: "vipNo",
            label: "会员编号",
            minWidth: 130,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 130,
          },
          {
            prop: "vipLevelNo",
            label: "会员等级编号",
            minWidth: 120,
          },
          {
            prop: "vipLevelName",
            label: "会员等级名称",
            minWidth: 160,
          },
          {
            prop: "shopNo",
            label: "门店编号",
            minWidth: 130,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 130,
          },
          {
            prop: "tel",
            label: "电话",
            minWidth: 130,
          },
          {
            prop: "usableScore",
            label: "剩余积分",
            minWidth: 130,
          },
          {
            prop: "allScore",
            label: "累计积分",
            minWidth: 130,
          },
          {
            prop: "distributionMan1Qty",
            label: "子会员数量",
            minWidth: 120,
          },
          {
            prop: "totalUsableMoney",
            label: "储值卡余额",
            minWidth: 120,
          },
          {
            prop: "totalSettlementCommission",
            label: "累计提现余额",
            minWidth: 120,
          },
        ],
         summary: [
          "usableScore",
          "allScore",
          "distributionMan1Qty",
          "totalUsableMoney",
          "totalSettlementCommission",
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "routerLink":
          {
            const objName = {
              110102: "PurchaseInstoreDetail",
              110103: "PurchaseBackDetail",
            };
            this.$router.push({
              name: objName[row.billType],
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() { },
  },
};
</script>
<style lang="scss" scoped></style>
