<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listShoppingDetailAPI,
  listShoppingDetailSummaryAPI,
} from "@/api/vip/report/consumeQuery"; //会员购物明细查询
export default {
  name: "consumeQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        uuid: "f92b940d-ce51-7256-e533-a77da577bf48",
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员购物查询",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员级别/会员卡号/会员名称/小票号/收银员名称/门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
              { filter: "billNos", label: "小票号" },
              { filter: "cashierNames", label: "收银员" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            option: {
              filter: "vipIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            label: "小票号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "会员购物查询明细",
            type: "detail",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询明细",
            },
            defaultBody: { summaryType: 0 },
            //表格
            columns: [
              {
                prop: "createTime",
                label: "日期",
                minWidth: 155,
                align: "center",
                // formatter: (propValue, row) => {
                //   return row.billDateStr + ' ' + row.billTimeStr
                // }
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 160,
                align: "center",
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "billNo",
                label: "小票号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 160,
                align: "center",
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "tel",
                label: "手机号",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 160,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitPrice",
                label: "售价",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney"],
          },
          {
            title: "会员购物查询（按会员卡汇总）",
            type: "vip",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询（按会员卡汇总）",
            },
            defaultBody: { summaryType: 1 },
            //表格
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "billMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["billMoney", "billFactMoney"],
          },
          {
            title: "会员购物查询（按小票汇总）",
            type: "billNo",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询（按小票汇总）",
            },
            defaultBody: { summaryType: 2 },
            //表格
            columns: [
              {
                prop: "createTime",
                label: "日期",
                minWidth: 155,
                align: "center",
                // formatter: (propValue, row) => {
                //   return row.billDateStr + ' ' + row.billTimeStr
                // }
              },
              {
                prop: "billNo",
                label: "小票号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "billNo",
                label: "内部单号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["billMoney", "billFactMoney"],
          },
          {
            title: "会员购物查询（按门店汇总）",
            type: "shop",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询（按门店汇总）",
            },
            defaultBody: { summaryType: 3 },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["billMoney", "billFactMoney"],
          },
          {
            title: "会员购物查询（按日期汇总）",
            type: "date",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询（按日期汇总）",
            },
            defaultBody: { summaryType: 4 },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
                align: "center",
                // formatter: (propValue, row) => {
                //   return row.billDateStr + ' ' + row.billTimeStr
                // }
              },
              {
                prop: "billMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["billMoney", "billFactMoney"],
          },
          {
            title: "会员购物查询（按会员级别汇总）",
            type: "vipLevel",
            getListApi: listShoppingDetailAPI,
            getSummaryApi: listShoppingDetailSummaryAPI,
            exportOption: {
              show: true,
              exportApi: "vipConsumeQuery",
              exportName: "会员购物查询（按会员级别汇总）",
            },
            defaultBody: { summaryType: 5 },
            //表格
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billMoney",
                label: "消费金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "付款金额",
                minWidth: 120,
                align: "center",
                sortable: true,
              },
            ],
            summary: ["billMoney", "billFactMoney"],
          },
        ],
      },
    };
  },
};
</script>
