<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import { wxVipLaunchList } from "@/api/vip/wxService/putInAnalyse";
export default {
  name: "putInAnalyse",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "699db9f6-2345-0281-f183-86c9abe91b08",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: wxVipLaunchList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "领取日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "viplevel",
            filter: "vipLevelIds",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            filter: "vipIds",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            label: "投放门店",
            filter: "shopIds",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopNo",
            label: "投放门店编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "投放门店名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "createTime",
            label: "领取时间",
            minWidth: 150,
            align: "center",
          },
        ],
      },
    };
  },
};
</script>
