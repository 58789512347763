<template>
  <div class="wrap">
    <TablePage />
  </div>
</template>
<script>
import TablePage from '@/views/marketing/timesCard/vipQuery'
export default {
  name: 'TimesCardQuery',
  components: {
    TablePage
  },
  data () {
    return {
     
    }
  }
}
</script>
