<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listCardEmitDetailAPI } from "@/api/vip/report/emitQuery"; //发卡记录
export default {
  name: "emitQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "809e41cd-f098-a960-88c6-7e288340a523",
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listCardEmitDetailAPI,
        title: "发卡记录查询",
        rowKey: "vipId",
        exportOption: {
          exportApi: "vipEmitQuery",
          exportName: "会员发卡记录",
        },
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编码/门店名称/级别编码/级别名称/会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            option: {
              filter: "vipIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "createTime",
            label: "日期",
            minWidth: 150,
          },
          {
            prop: "vipLevelNo",
            label: "级别编号",
            minWidth: 150,
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 150,
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 150,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 150,
          },
          {
            prop: "shopNo",
            label: "门店编号",
            minWidth: 150,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "发放人员",
            minWidth: 150,
          },
        ],
      },
    };
  },
};
</script>
