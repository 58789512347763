<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 弹窗 -->
    <Dialog ref="dialog" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  msgTemplateListAPI,
  msgTemplateImportAPI,
  msgTemplateIsUseAPI,
  msgTemplateDeleteAPI,
  msgSetUpMsgType,
} from "@/api/vip/wxService/msgTemplate"; //微信消息的模板
export default {
  name: "msgTemplate",
  dicts: ["remind_type_id", "wx_app_type"],
  components: { TablePage, Dialog },
  data() {
    return {
      options: {
        uuid: "adc6be2b-42f5-c8a1-962a-08b5ac7ad823",
        rowClick: true,
        mutiSelect: true, // 多选
        tableTitle: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "PLUS会员列表",
        getListApi: msgTemplateListAPI,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //按钮
        buttons: [
          {
            click: "add",
            label: "引入模板",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "add-default",
            label: "引入默认模板",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "是否删除选中的数据",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "templetMsgId",
            label: "微信模板ID",
            minWidth: 150,
            align: "center",
            type: "link",
            click: "update",
          },
          {
            prop: "wxTempletHeadContent",
            label: "消息标题",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "wxTempletTitle",
            label: "微信消息标题",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "wxAppTypeName",
            label: "消息类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "wxTempletEndContent",
            label: "使用模式",
            minWidth: 120,
            align: "center",
          },
          {
            type: "my-select-local",
            prop: "remindTypeId",
            label: "消息提醒类型",
            minWidth: 120,
            align: "center",
            rowChange: async (row) => {
              try {
                await msgSetUpMsgType({
                  remindTypeId: row.remindTypeId,
                  templetMsgId: row.templetMsgId,
                });
                this.$refs.tablePage.getList();
              } catch (err) {}
            },
            option: {
              remote: this.getDicts,
              remoteBody: "remind_type_id",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          {
            prop: "isUseWxTemplet",
            label: "启用",
            minWidth: 150,
            align: "center",
            type: "switch",
            active: true,
            inactive: false,
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        title: "导入模板",
        width: 600,
        show: false,
        type: "ImportWXTemplate",
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "add":
          //弹窗配置
          this.dialogOptions = {
            title: "设置消息类型参数",
            width: 600,
            show: true,
            type: "SetWXMsgType",
            click: type,
          };
          break;
        case "add-default":
          try {
            await msgTemplateImportAPI();
            this.$message({
              message: `引入默认模板信息成功`,
              type: "success",
            });
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "update":
          if (!row.msgTypeData) return this.$message.warning("请先选择消息提醒类型");
          //弹窗配置
          this.dialogOptions = {
            title: "设置消息类型参数",
            width: 600,
            show: true,
            type: "SetWXMsgType",
            click: type,
            formData: row,
          };
          break;
        case "switch":
          try {
            await msgTemplateIsUseAPI(row.templetMsgId);
            this.$message({
              message: `修改成功`,
              type: "success",
            });
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "del":
          try {
            await msgTemplateDeleteAPI(
              this.options.check.map((item) => item.templetMsgId)
            );
            this.$message.success("删除成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
