<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { getVipBirthday, getVipBirthdaySummary } from "@/api/vip/report/birthdayQuery.js"; //会员充值接口
import { getVipReportDefaultCondition } from "@/api/vip/report/lossQuery.js";
export default {
  name: "birthdayQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "f45e6e69-5a57-3ce0-0464-f661f98142b2",
        loading: true,
        listNo: true, // 序号
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: async () => {
          try {
            const { data } = await getVipReportDefaultCondition(1);
            if (data) {
              this.options.search[5].model = 2;
              let startDate = data.beg.split("-");
              startDate.splice(0, 1);
              startDate = startDate.join("-");
              let endDate = data.end.split("-");
              endDate.splice(0, 1);
              endDate = endDate.join("-");
              this.options.search[7].model = [startDate, endDate];
              return {
                birthdayType: 2,
                birthday: startDate,
                endBirthday: endDate,
              };
            }
          } catch (err) {}
        },
        getListApi: getVipBirthday,
        getSummaryApi: getVipBirthdaySummary,
        body: {}, // 请求内容
        title: "会员生日查询",
        rowKey: "vipNo",
        rowKey: "vipNo",
        exportOption: {
          exportApi: "vipBirthdayQuery",
          exportName: "会员生日",
        },
        search: [
          {
            type: "filters",
            tip: "会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员姓名" },
              { filter: "tels", label: "手机号" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            label: "生日类型",
            type: "radio",
            model: undefined,
            seniorSearch: true,
            option: {
              type: "clear",
              clear: ["birthday"],
              data: [
                { value: 1, label: "月份" },
                { value: 2, label: "日期" },
              ],
            },
            defaultValue: 1,
            model: "",
          },
          {
            label: "生日月份",
            type: "local",
            seniorSearch: true,
            hide: (arr) =>
              Boolean(arr.find((x) => x.defaultValue === 1 && x.model === 1)),
            model: "",
            option: {
              multiple: true,
              value: "value",
              label: "label",
              data: [
                { value: "01", label: "一月" },
                { value: "02", label: "二月" },
                { value: "03", label: "三月" },
                { value: "04", label: "四月" },
                { value: "05", label: "五月" },
                { value: "06", label: "六月" },
                { value: "07", label: "七月" },
                { value: "08", label: "八月" },
                { value: "09", label: "九月" },
                { value: "10", label: "十月" },
                { value: "11", label: "十一月" },
                { value: "12", label: "十二月" },
              ],
            },
            filterFnc: (v) => ({ birthdays: v, birthdayType: 1 }),
          },
          {
            label: "生日日期",
            type: "MDrange",
            model: undefined,
            seniorSearch: true,
            hide: (arr) =>
              Boolean(arr.find((x) => x.defaultValue === 1 && x.model === 2)),
            model: "",
            filterFnc: ([a, b] = []) => ({
              birthday: a,
              endBirthday: b,
              birthdayType: 2,
            }),
          },
        ],
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 150,
          },
          {
            prop: "vipName",
            label: "会员姓名",
            minWidth: 150,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 150,
          },
          {
            prop: "vipLevelNo",
            label: "级别编号",
            minWidth: 150,
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 150,
          },
          {
            prop: "birthday",
            label: "会员生日",
            minWidth: 150,
          },
          {
            prop: "totalUsableMoney",
            label: "卡内总金额",
            minWidth: 150,
          },
          {
            prop: "usableScore",
            label: "可用积分",
            minWidth: 150,
          },
        ],
        summary: ["totalUsableMoney", "usableScore"],
      },
      shopInfo: [],
      tenantInfo: [],
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
