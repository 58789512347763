<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import { vipShareList } from "@/api/vip/wxService/vipShare"; //微信消息的模板
export default {
  name: "vipShare",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "7807fc15-02b3-6ac4-0393-a6ea6d858cd8",
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "PLUS会员列表",
        getListApi: vipShareList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "推荐人名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shareVipNames", label: "推荐人名称" },
              { filter: "shareVipTels", label: "手机号" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "shareVipName",
            label: "推荐人名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shareVipTel",
            label: "推荐人手机号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "openVipName",
            label: "开卡人名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "openVipTel",
            label: "开卡人手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "cardStatusName",
            label: "状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "activateTime",
            label: "开卡时间",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shareVipTime",
            label: "推荐时间",
            minWidth: 150,
            align: "center",
          },
        ],
      },
    };
  },
};
</script>
